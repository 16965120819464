/** 
 * Global classes, methods, values, and helpers. As we migrate
 * away from Sprockets this can be a staging area for all globals.
 * 
 * ENSURE THIS IS LOADED FIRST!
**/

/** Includes all images under app/assets/images/ */
require.context('../images', true)

// From yarn we import this and assign it to the window. Refactor candidates.
import '../javascripts/vendor/yarn_to_window_jQuery'
import '../javascripts/vendor/yarn_to_window_lodash'
import '../javascripts/vendor/yarn_to_window_underscore'
import '../javascripts/vendor/yarn_to_window_react'
import '../javascripts/vendor/yarn_to_window_bugsnag'
import '../javascripts/vendor/yarn_to_window_moment'
import "../javascripts/vendor/yarn_to_window_URI"
import "../javascripts/vendor/yarn_to_window_timeme"
import "../javascripts/vendor/yarn_to_window_Chart"
import '../javascripts/vendor/yarn_to_window_bootbox'
import "../javascripts/vendor/yarn_to_window_nprogress"
import "../javascripts/vendor/yarn_to_window_sortable"
import "../javascripts/vendor/yarn_to_window_stickybits"

// Auto assigend to global context
import "jquery-color"
import "jquery-deparam" 
import "moment-timezone"
import "jquery-migrate"
// change this to false to allow console logging of all the breaking changes... upgrading bootstrap will help immensely
jQuery.migrateMute = true;
import "jquery.growl/javascripts/jquery.growl"

// Yarn v4 isn't playing nice with tabelsorter on NPM. Some breaking changes were expected when
// upgrading from v1, could use more investigation in the future.
import "../javascripts/vendor/no_yarn_tablesorter"

// Backbone version not found via yarn -- therefore, until we retire Backbone, we import this file.
import "../javascripts/vendor/yarn_to_window_backbone"

// scrollExtend package not found on NPM -- website is defunct. I reviewed different solutions however none
// allow for a custom method to fire on scroll end. Since this is an integral part of the application (it powers
// the scroll behaviour on facet_search, fetching new events once a user has hit the end of current event payload)
// we should strongly consider a spike to find a suitable alternative.
import "../javascripts/vendor/no_yarn_scrollExtend"

// Twitter's Typeahead package not found on NPM...see file for more details.
import '../javascripts/vendor/no_yarn_typeahead'

// jQuery pulse not found anywhere on NPM
import "../javascripts/vendor/no_yarn_jquery_pulse"

// air-datepicker has been heavily customized by us
import "../javascripts/vendor/no_yarn_air_datepicker"

// We import this and assign the resulting function to the window. Refactor candidate.
import '../javascripts/globals/window_backbone_templates'

/** i18n. The order here is important. */
import '../javascripts/vendor/yarn_to_window_i18n'
import '../javascripts/i18n/translations' // Must be loaded AFTER i18n vendor!

// Globals that we created
import '../javascripts/shared/typeahead_mods'

/** Global Reflux stores */
import "../javascripts/stores/reflux_to_window_stores"
import "../javascripts/reactComponents/react_to_window_components"

// JST templates that will be globally assigned later
import "../javascripts/globals/window_jst_templates"

/** Anything that needs to be loaded AFTER legacy code! */
window.__hacky_run_append_vendor_imports = function() {  
  window.__hacky_load_typeahead()
}
