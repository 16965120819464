import FacetElementsJST from "../templates/facet_search/facet_elements.jst.ejs"
import FacetPanelJST from "../templates/facet_search/facet_panel.jst.ejs"

import CatTitleBarJST from "../templates/shared/cat_title_bar.jst.ejs"
import EventPanelJST from "../templates/shared/event_panel.jst.ejs"
import EventModalJST from "../templates/shared/event_modal.jst.ejs"
import TimePanelJST from "../templates/shared/time_panel.jst.ejs"
import OrganizationsSectionJST from "../templates/shared/organizations_section.jst.ejs"

import EmbedListEditorMenuJST from "../templates/lists/embed_list_editor_menu.jst.ejs"
import DisplayListInfoJST from "../templates/lists/display_list_info.jst.ejs"
import DisplayMangeListJST from "../templates/lists/display_manage_list.jst.ejs"
import ListCurrentItemJST from "../templates/lists/list_current_item.jst.ejs"
import ListEditorMenuJST from "../templates/lists/list_editor_menu.jst.ejs"
import ListToAddJST from "../templates/lists/list_to_add.jst.ejs"

/**
 * Load JST templates, taken with help from: https://ricostacruz.com/backbone-patterns/jst-templates.html.
 */
window.JST = {};
window.JST["templates/facet_search/facet_elements"] = window._.template(FacetElementsJST)
window.JST["templates/facet_search/facet_panel"] = window._.template(FacetPanelJST)

window.JST["templates/shared/cat_title_bar"] = window._.template(CatTitleBarJST)
window.JST["templates/shared/event_panel"] = window._.template(EventPanelJST)
window.JST["templates/shared/event_modal"] = window._.template(EventModalJST)
window.JST["templates/shared/time_panel"] = window._.template(TimePanelJST)
window.JST["templates/shared/organizations_section"] = window._.template(OrganizationsSectionJST)

window.JST["templates/lists/embed_list_editor_menu"] = window._.template(EmbedListEditorMenuJST)
window.JST["templates/lists/display_list_info"] = window._.template(DisplayListInfoJST)
window.JST["templates/lists/display_manage_list"] = window._.template(DisplayMangeListJST)
window.JST["templates/lists/list_current_item"] = window._.template(ListCurrentItemJST)
window.JST["templates/lists/list_editor_menu"] = window._.template(ListEditorMenuJST)
window.JST["templates/lists/list_to_add"] = window._.template(ListToAddJST)
