/**
 * Created by ryan on 20/02/15.
 */
if (window.Zymewire) {
}
else {
    window.Zymewire = {};
}




Zymewire.autocomplete_search = function (query_str, callback) {
    // console.log(query_str);
    $.getJSON('/autocomplete/search_autocomplete', {text: query_str}, function (res) {
        // console.log(res.results);
        callback(res.results);
    });

};

Zymewire.autocomplete_map_search = function (query_str, callback) {
    // console.log(query_str);
    $.getJSON('/autocomplete/map_page_autocomplete', {text: query_str}, function (res) {
        // console.log(res.results);
        callback(res.results);
    });

};

var rSpacesOrHyphens = / |\-/;
Zymewire.autocomplete_suggestion = function (context) {
    if (context.current_search) {
        return "<p class='typeahead_display' id=\"current_search_suggestion\">" + context.display + "  <i>- current search</i></p>";
    }
    else if (context.starts_with_search) {
        return "<p class='typeahead_display'>" + context.display + "  <i>- starts with</i></p>";
    }
    else if (context.recent_search) {
        return "<p class='typeahead_display'>" + context.display + "  <i>- recent search</i></p>";
    }
    else if (context.type === "source_doc_search") {
        return "<p class='typeahead_display'>" + context.display + "  <i>- in source documents</i></p>";
    }
    else if (context.place_id){
        return "<p class='typeahead_display typeahead_location'>" + context.display + "  <i class='icon-globe right'></i></p>";
    }
    else {
        return "<p class='typeahead_display'>" + context.display + "</p>";

    }
};


Zymewire.autocomplete_config = {
    hint: true,
    highlight: true,
    minLength: 3
};

Zymewire.autocomplete_source = {
    name: 'query',
    displayKey: 'matched',
    source: Zymewire.autocomplete_search,
    templates: {suggestion: Zymewire.autocomplete_suggestion}
};

Zymewire.autocomplete_map_source = {
    name: 'query',
    displayKey: 'matched',
    source: Zymewire.autocomplete_map_search,
    templates: {suggestion: Zymewire.autocomplete_suggestion}
};






