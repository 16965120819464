/*!
 * typeahead.js 0.10.5
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
/** Downloaded from: https://twitter.github.io/typeahead.js/releases/0.10.5/typeahead.bundle.js */


/** 
 * IMPORTANT: 
 * The logic here is wrapped inside a function
 * because it needs to be called after bootstrap is loaded,
 * in order to avoid conflicts.
 */
window.__hacky_load_typeahead = function() {
    (function($) {
        var _ = function() {
            "use strict";
            return {
                isMsie: function() {
                    return /(msie|trident)/i.test(navigator.userAgent) ? navigator.userAgent.match(/(msie |rv:)(\d+(.\d+)?)/i)[2] : false;
                },
                isBlankString: function(str) {
                    return !str || /^\s*$/.test(str);
                },
                escapeRegExChars: function(str) {
                    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                },
                isString: function(obj) {
                    return typeof obj === "string";
                },
                isNumber: function(obj) {
                    return typeof obj === "number";
                },
                isArray: $.isArray,
                isFunction: $.isFunction,
                isObject: $.isPlainObject,
                isUndefined: function(obj) {
                    return typeof obj === "undefined";
                },
                toStr: function toStr(s) {
                    return _.isUndefined(s) || s === null ? "" : s + "";
                },
                bind: $.proxy,
                each: function(collection, cb) {
                    $.each(collection, reverseArgs);
                    function reverseArgs(index, value) {
                        return cb(value, index);
                    }
                },
                map: $.map,
                filter: $.grep,
                every: function(obj, test) {
                    var result = true;
                    if (!obj) {
                        return result;
                    }
                    $.each(obj, function(key, val) {
                        if (!(result = test.call(null, val, key, obj))) {
                            return false;
                        }
                    });
                    return !!result;
                },
                some: function(obj, test) {
                    var result = false;
                    if (!obj) {
                        return result;
                    }
                    $.each(obj, function(key, val) {
                        if (result = test.call(null, val, key, obj)) {
                            return false;
                        }
                    });
                    return !!result;
                },
                mixin: $.extend,
                getUniqueId: function() {
                    var counter = 0;
                    return function() {
                        return counter++;
                    };
                }(),
                templatify: function templatify(obj) {
                    return $.isFunction(obj) ? obj : template;
                    function template() {
                        return String(obj);
                    }
                },
                defer: function(fn) {
                    setTimeout(fn, 0);
                },
                debounce: function(func, wait, immediate) {
                    var timeout, result;
                    return function() {
                        var context = this, args = arguments, later, callNow;
                        later = function() {
                            timeout = null;
                            if (!immediate) {
                                result = func.apply(context, args);
                            }
                        };
                        callNow = immediate && !timeout;
                        clearTimeout(timeout);
                        timeout = setTimeout(later, wait);
                        if (callNow) {
                            result = func.apply(context, args);
                        }
                        return result;
                    };
                },
                throttle: function(func, wait) {
                    var context, args, timeout, result, previous, later;
                    previous = 0;
                    later = function() {
                        previous = new Date();
                        timeout = null;
                        result = func.apply(context, args);
                    };
                    return function() {
                        var now = new Date(), remaining = wait - (now - previous);
                        context = this;
                        args = arguments;
                        if (remaining <= 0) {
                            clearTimeout(timeout);
                            timeout = null;
                            previous = now;
                            result = func.apply(context, args);
                        } else if (!timeout) {
                            timeout = setTimeout(later, remaining);
                        }
                        return result;
                    };
                },
                noop: function() {}
            };
        }();
        var VERSION = "0.10.5";
        var tokenizers = function() {
            "use strict";
            return {
                nonword: nonword,
                whitespace: whitespace,
                obj: {
                    nonword: getObjTokenizer(nonword),
                    whitespace: getObjTokenizer(whitespace)
                }
            };
            function whitespace(str) {
                str = _.toStr(str);
                return str ? str.split(/\s+/) : [];
            }
            function nonword(str) {
                str = _.toStr(str);
                return str ? str.split(/\W+/) : [];
            }
            function getObjTokenizer(tokenizer) {
                return function setKey() {
                    var args = [].slice.call(arguments, 0);
                    return function tokenize(o) {
                        var tokens = [];
                        _.each(args, function(k) {
                            tokens = tokens.concat(tokenizer(_.toStr(o[k])));
                        });
                        return tokens;
                    };
                };
            }
        }();
        var LruCache = function() {
            "use strict";
            function LruCache(maxSize) {
                this.maxSize = _.isNumber(maxSize) ? maxSize : 100;
                this.reset();
                if (this.maxSize <= 0) {
                    this.set = this.get = $.noop;
                }
            }
            _.mixin(LruCache.prototype, {
                set: function set(key, val) {
                    var tailItem = this.list.tail, node;
                    if (this.size >= this.maxSize) {
                        this.list.remove(tailItem);
                        delete this.hash[tailItem.key];
                    }
                    if (node = this.hash[key]) {
                        node.val = val;
                        this.list.moveToFront(node);
                    } else {
                        node = new Node(key, val);
                        this.list.add(node);
                        this.hash[key] = node;
                        this.size++;
                    }
                },
                get: function get(key) {
                    var node = this.hash[key];
                    if (node) {
                        this.list.moveToFront(node);
                        return node.val;
                    }
                },
                reset: function reset() {
                    this.size = 0;
                    this.hash = {};
                    this.list = new List();
                }
            });
            function List() {
                this.head = this.tail = null;
            }
            _.mixin(List.prototype, {
                add: function add(node) {
                    if (this.head) {
                        node.next = this.head;
                        this.head.prev = node;
                    }
                    this.head = node;
                    this.tail = this.tail || node;
                },
                remove: function remove(node) {
                    node.prev ? node.prev.next = node.next : this.head = node.next;
                    node.next ? node.next.prev = node.prev : this.tail = node.prev;
                },
                moveToFront: function(node) {
                    this.remove(node);
                    this.add(node);
                }
            });
            function Node(key, val) {
                this.key = key;
                this.val = val;
                this.prev = this.next = null;
            }
            return LruCache;
        }();
        var PersistentStorage = function() {
            "use strict";
            var ls, methods;
            try {
                ls = window.localStorage;
                ls.setItem("~~~", "!");
                ls.removeItem("~~~");
            } catch (err) {
                ls = null;
            }
            function PersistentStorage(namespace) {
                this.prefix = [ "__", namespace, "__" ].join("");
                this.ttlKey = "__ttl__";
                this.keyMatcher = new RegExp("^" + _.escapeRegExChars(this.prefix));
            }
            if (ls && window.JSON) {
                methods = {
                    _prefix: function(key) {
                        return this.prefix + key;
                    },
                    _ttlKey: function(key) {
                        return this._prefix(key) + this.ttlKey;
                    },
                    get: function(key) {
                        if (this.isExpired(key)) {
                            this.remove(key);
                        }
                        return decode(ls.getItem(this._prefix(key)));
                    },
                    set: function(key, val, ttl) {
                        if (_.isNumber(ttl)) {
                            ls.setItem(this._ttlKey(key), encode(now() + ttl));
                        } else {
                            ls.removeItem(this._ttlKey(key));
                        }
                        return ls.setItem(this._prefix(key), encode(val));
                    },
                    remove: function(key) {
                        ls.removeItem(this._ttlKey(key));
                        ls.removeItem(this._prefix(key));
                        return this;
                    },
                    clear: function() {
                        var i, key, keys = [], len = ls.length;
                        for (i = 0; i < len; i++) {
                            if ((key = ls.key(i)).match(this.keyMatcher)) {
                                keys.push(key.replace(this.keyMatcher, ""));
                            }
                        }
                        for (i = keys.length; i--; ) {
                            this.remove(keys[i]);
                        }
                        return this;
                    },
                    isExpired: function(key) {
                        var ttl = decode(ls.getItem(this._ttlKey(key)));
                        return _.isNumber(ttl) && now() > ttl ? true : false;
                    }
                };
            } else {
                methods = {
                    get: _.noop,
                    set: _.noop,
                    remove: _.noop,
                    clear: _.noop,
                    isExpired: _.noop
                };
            }
            _.mixin(PersistentStorage.prototype, methods);
            return PersistentStorage;
            function now() {
                return new Date().getTime();
            }
            function encode(val) {
                return JSON.stringify(_.isUndefined(val) ? null : val);
            }
            function decode(val) {
                return JSON.parse(val);
            }
        }();
        var Transport = function() {
            "use strict";
            var pendingRequestsCount = 0, pendingRequests = {}, maxPendingRequests = 6, sharedCache = new LruCache(10);
            function Transport(o) {
                o = o || {};
                this.cancelled = false;
                this.lastUrl = null;
                this._send = o.transport ? callbackToDeferred(o.transport) : $.ajax;
                this._get = o.rateLimiter ? o.rateLimiter(this._get) : this._get;
                this._cache = o.cache === false ? new LruCache(0) : sharedCache;
            }
            Transport.setMaxPendingRequests = function setMaxPendingRequests(num) {
                maxPendingRequests = num;
            };
            Transport.resetCache = function resetCache() {
                sharedCache.reset();
            };
            _.mixin(Transport.prototype, {
                _get: function(url, o, cb) {
                    var that = this, jqXhr;
                    if (this.cancelled || url !== this.lastUrl) {
                        return;
                    }
                    if (jqXhr = pendingRequests[url]) {
                        jqXhr.done(done).fail(fail);
                    } else if (pendingRequestsCount < maxPendingRequests) {
                        pendingRequestsCount++;
                        pendingRequests[url] = this._send(url, o).done(done).fail(fail).always(always);
                    } else {
                        this.onDeckRequestArgs = [].slice.call(arguments, 0);
                    }
                    function done(resp) {
                        cb && cb(null, resp);
                        that._cache.set(url, resp);
                    }
                    function fail() {
                        cb && cb(true);
                    }
                    function always() {
                        pendingRequestsCount--;
                        delete pendingRequests[url];
                        if (that.onDeckRequestArgs) {
                            that._get.apply(that, that.onDeckRequestArgs);
                            that.onDeckRequestArgs = null;
                        }
                    }
                },
                get: function(url, o, cb) {
                    var resp;
                    if (_.isFunction(o)) {
                        cb = o;
                        o = {};
                    }
                    this.cancelled = false;
                    this.lastUrl = url;
                    if (resp = this._cache.get(url)) {
                        _.defer(function() {
                            cb && cb(null, resp);
                        });
                    } else {
                        this._get(url, o, cb);
                    }
                    return !!resp;
                },
                cancel: function() {
                    this.cancelled = true;
                }
            });
            return Transport;
            function callbackToDeferred(fn) {
                return function customSendWrapper(url, o) {
                    var deferred = $.Deferred();
                    fn(url, o, onSuccess, onError);
                    return deferred;
                    function onSuccess(resp) {
                        _.defer(function() {
                            deferred.resolve(resp);
                        });
                    }
                    function onError(err) {
                        _.defer(function() {
                            deferred.reject(err);
                        });
                    }
                };
            }
        }();
        var SearchIndex = function() {
            "use strict";
            function SearchIndex(o) {
                o = o || {};
                if (!o.datumTokenizer || !o.queryTokenizer) {
                    $.error("datumTokenizer and queryTokenizer are both required");
                }
                this.datumTokenizer = o.datumTokenizer;
                this.queryTokenizer = o.queryTokenizer;
                this.reset();
            }
            _.mixin(SearchIndex.prototype, {
                bootstrap: function bootstrap(o) {
                    this.datums = o.datums;
                    this.trie = o.trie;
                },
                add: function(data) {
                    var that = this;
                    data = _.isArray(data) ? data : [ data ];
                    _.each(data, function(datum) {
                        var id, tokens;
                        id = that.datums.push(datum) - 1;
                        tokens = normalizeTokens(that.datumTokenizer(datum));
                        _.each(tokens, function(token) {
                            var node, chars, ch;
                            node = that.trie;
                            chars = token.split("");
                            while (ch = chars.shift()) {
                                node = node.children[ch] || (node.children[ch] = newNode());
                                node.ids.push(id);
                            }
                        });
                    });
                },
                get: function get(query) {
                    var that = this, tokens, matches;
                    tokens = normalizeTokens(this.queryTokenizer(query));
                    _.each(tokens, function(token) {
                        var node, chars, ch, ids;
                        if (matches && matches.length === 0) {
                            return false;
                        }
                        node = that.trie;
                        chars = token.split("");
                        while (node && (ch = chars.shift())) {
                            node = node.children[ch];
                        }
                        if (node && chars.length === 0) {
                            ids = node.ids.slice(0);
                            matches = matches ? getIntersection(matches, ids) : ids;
                        } else {
                            matches = [];
                            return false;
                        }
                    });
                    return matches ? _.map(unique(matches), function(id) {
                        return that.datums[id];
                    }) : [];
                },
                reset: function reset() {
                    this.datums = [];
                    this.trie = newNode();
                },
                serialize: function serialize() {
                    return {
                        datums: this.datums,
                        trie: this.trie
                    };
                }
            });
            return SearchIndex;
            function normalizeTokens(tokens) {
                tokens = _.filter(tokens, function(token) {
                    return !!token;
                });
                tokens = _.map(tokens, function(token) {
                    return token.toLowerCase();
                });
                return tokens;
            }
            function newNode() {
                return {
                    ids: [],
                    children: {}
                };
            }
            function unique(array) {
                var seen = {}, uniques = [];
                for (var i = 0, len = array.length; i < len; i++) {
                    if (!seen[array[i]]) {
                        seen[array[i]] = true;
                        uniques.push(array[i]);
                    }
                }
                return uniques;
            }
            function getIntersection(arrayA, arrayB) {
                var ai = 0, bi = 0, intersection = [];
                arrayA = arrayA.sort(compare);
                arrayB = arrayB.sort(compare);
                var lenArrayA = arrayA.length, lenArrayB = arrayB.length;
                while (ai < lenArrayA && bi < lenArrayB) {
                    if (arrayA[ai] < arrayB[bi]) {
                        ai++;
                    } else if (arrayA[ai] > arrayB[bi]) {
                        bi++;
                    } else {
                        intersection.push(arrayA[ai]);
                        ai++;
                        bi++;
                    }
                }
                return intersection;
                function compare(a, b) {
                    return a - b;
                }
            }
        }();
        var oParser = function() {
            "use strict";
            return {
                local: getLocal,
                prefetch: getPrefetch,
                remote: getRemote
            };
            function getLocal(o) {
                return o.local || null;
            }
            function getPrefetch(o) {
                var prefetch, defaults;
                defaults = {
                    url: null,
                    thumbprint: "",
                    ttl: 24 * 60 * 60 * 1e3,
                    filter: null,
                    ajax: {}
                };
                if (prefetch = o.prefetch || null) {
                    prefetch = _.isString(prefetch) ? {
                        url: prefetch
                    } : prefetch;
                    prefetch = _.mixin(defaults, prefetch);
                    prefetch.thumbprint = VERSION + prefetch.thumbprint;
                    prefetch.ajax.type = prefetch.ajax.type || "GET";
                    prefetch.ajax.dataType = prefetch.ajax.dataType || "json";
                    !prefetch.url && $.error("prefetch requires url to be set");
                }
                return prefetch;
            }
            function getRemote(o) {
                var remote, defaults;
                defaults = {
                    url: null,
                    cache: true,
                    wildcard: "%QUERY",
                    replace: null,
                    rateLimitBy: "debounce",
                    rateLimitWait: 300,
                    send: null,
                    filter: null,
                    ajax: {}
                };
                if (remote = o.remote || null) {
                    remote = _.isString(remote) ? {
                        url: remote
                    } : remote;
                    remote = _.mixin(defaults, remote);
                    remote.rateLimiter = /^throttle$/i.test(remote.rateLimitBy) ? byThrottle(remote.rateLimitWait) : byDebounce(remote.rateLimitWait);
                    remote.ajax.type = remote.ajax.type || "GET";
                    remote.ajax.dataType = remote.ajax.dataType || "json";
                    delete remote.rateLimitBy;
                    delete remote.rateLimitWait;
                    !remote.url && $.error("remote requires url to be set");
                }
                return remote;
                function byDebounce(wait) {
                    return function(fn) {
                        return _.debounce(fn, wait);
                    };
                }
                function byThrottle(wait) {
                    return function(fn) {
                        return _.throttle(fn, wait);
                    };
                }
            }
        }();
        (function(root) {
            "use strict";
            var old, keys;
            old = root.Bloodhound;
            keys = {
                data: "data",
                protocol: "protocol",
                thumbprint: "thumbprint"
            };
            root.Bloodhound = Bloodhound;
            function Bloodhound(o) {
                if (!o || !o.local && !o.prefetch && !o.remote) {
                    $.error("one of local, prefetch, or remote is required");
                }
                this.limit = o.limit || 5;
                this.sorter = getSorter(o.sorter);
                this.dupDetector = o.dupDetector || ignoreDuplicates;
                this.local = oParser.local(o);
                this.prefetch = oParser.prefetch(o);
                this.remote = oParser.remote(o);
                this.cacheKey = this.prefetch ? this.prefetch.cacheKey || this.prefetch.url : null;
                this.index = new SearchIndex({
                    datumTokenizer: o.datumTokenizer,
                    queryTokenizer: o.queryTokenizer
                });
                this.storage = this.cacheKey ? new PersistentStorage(this.cacheKey) : null;
            }
            Bloodhound.noConflict = function noConflict() {
                root.Bloodhound = old;
                return Bloodhound;
            };
            Bloodhound.tokenizers = tokenizers;
            _.mixin(Bloodhound.prototype, {
                _loadPrefetch: function loadPrefetch(o) {
                    var that = this, serialized, deferred;
                    if (serialized = this._readFromStorage(o.thumbprint)) {
                        this.index.bootstrap(serialized);
                        deferred = $.Deferred().resolve();
                    } else {
                        deferred = $.ajax(o.url, o.ajax).done(handlePrefetchResponse);
                    }
                    return deferred;
                    function handlePrefetchResponse(resp) {
                        that.clear();
                        that.add(o.filter ? o.filter(resp) : resp);
                        that._saveToStorage(that.index.serialize(), o.thumbprint, o.ttl);
                    }
                },
                _getFromRemote: function getFromRemote(query, cb) {
                    var that = this, url, uriEncodedQuery;
                    if (!this.transport) {
                        return;
                    }
                    query = query || "";
                    uriEncodedQuery = encodeURIComponent(query);
                    url = this.remote.replace ? this.remote.replace(this.remote.url, query) : this.remote.url.replace(this.remote.wildcard, uriEncodedQuery);
                    return this.transport.get(url, this.remote.ajax, handleRemoteResponse);
                    function handleRemoteResponse(err, resp) {
                        err ? cb([]) : cb(that.remote.filter ? that.remote.filter(resp) : resp);
                    }
                },
                _cancelLastRemoteRequest: function cancelLastRemoteRequest() {
                    this.transport && this.transport.cancel();
                },
                _saveToStorage: function saveToStorage(data, thumbprint, ttl) {
                    if (this.storage) {
                        this.storage.set(keys.data, data, ttl);
                        this.storage.set(keys.protocol, location.protocol, ttl);
                        this.storage.set(keys.thumbprint, thumbprint, ttl);
                    }
                },
                _readFromStorage: function readFromStorage(thumbprint) {
                    var stored = {}, isExpired;
                    if (this.storage) {
                        stored.data = this.storage.get(keys.data);
                        stored.protocol = this.storage.get(keys.protocol);
                        stored.thumbprint = this.storage.get(keys.thumbprint);
                    }
                    isExpired = stored.thumbprint !== thumbprint || stored.protocol !== location.protocol;
                    return stored.data && !isExpired ? stored.data : null;
                },
                _initialize: function initialize() {
                    var that = this, local = this.local, deferred;
                    deferred = this.prefetch ? this._loadPrefetch(this.prefetch) : $.Deferred().resolve();
                    local && deferred.done(addLocalToIndex);
                    this.transport = this.remote ? new Transport(this.remote) : null;
                    return this.initPromise = deferred.promise();
                    function addLocalToIndex() {
                        that.add(_.isFunction(local) ? local() : local);
                    }
                },
                initialize: function initialize(force) {
                    return !this.initPromise || force ? this._initialize() : this.initPromise;
                },
                add: function add(data) {
                    this.index.add(data);
                },
                get: function get(query, cb) {
                    var that = this, matches = [], cacheHit = false;
                    matches = this.index.get(query);
                    matches = this.sorter(matches).slice(0, this.limit);
                    matches.length < this.limit ? cacheHit = this._getFromRemote(query, returnRemoteMatches) : this._cancelLastRemoteRequest();
                    if (!cacheHit) {
                        (matches.length > 0 || !this.transport) && cb && cb(matches);
                    }
                    function returnRemoteMatches(remoteMatches) {
                        var matchesWithBackfill = matches.slice(0);
                        _.each(remoteMatches, function(remoteMatch) {
                            var isDuplicate;
                            isDuplicate = _.some(matchesWithBackfill, function(match) {
                                return that.dupDetector(remoteMatch, match);
                            });
                            !isDuplicate && matchesWithBackfill.push(remoteMatch);
                            return matchesWithBackfill.length < that.limit;
                        });
                        cb && cb(that.sorter(matchesWithBackfill));
                    }
                },
                clear: function clear() {
                    this.index.reset();
                },
                clearPrefetchCache: function clearPrefetchCache() {
                    this.storage && this.storage.clear();
                },
                clearRemoteCache: function clearRemoteCache() {
                    this.transport && Transport.resetCache();
                },
                ttAdapter: function ttAdapter() {
                    return _.bind(this.get, this);
                }
            });
            return Bloodhound;
            function getSorter(sortFn) {
                return _.isFunction(sortFn) ? sort : noSort;
                function sort(array) {
                    return array.sort(sortFn);
                }
                function noSort(array) {
                    return array;
                }
            }
            function ignoreDuplicates() {
                return false;
            }
        })(this);
        var html = function() {
            return {
                wrapper: '<span class="twitter-typeahead"></span>',
                dropdown: '<span class="tt-dropdown-menu"></span>',
                dataset: '<div class="tt-dataset-%CLASS%"></div>',
                suggestions: '<span class="tt-suggestions"></span>',
                suggestion: '<div class="tt-suggestion"></div>'
            };
        }();
        var css = function() {
            "use strict";
            var css = {
                wrapper: {
                    position: "relative",
                    display: "inline-block"
                },
                hint: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    borderColor: "transparent",
                    boxShadow: "none",
                    opacity: "1"
                },
                input: {
                    position: "relative",
                    verticalAlign: "top",
                    backgroundColor: "transparent"
                },
                inputWithNoHint: {
                    position: "relative",
                    verticalAlign: "top"
                },
                dropdown: {
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    zIndex: "100",
                    display: "none"
                },
                suggestions: {
                    display: "block"
                },
                suggestion: {
                    whiteSpace: "nowrap",
                    cursor: "pointer"
                },
                suggestionChild: {
                    whiteSpace: "normal"
                },
                ltr: {
                    left: "0",
                    right: "auto"
                },
                rtl: {
                    left: "auto",
                    right: " 0"
                }
            };
            if (_.isMsie()) {
                _.mixin(css.input, {
                    backgroundImage: "url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)"
                });
            }
            if (_.isMsie() && _.isMsie() <= 7) {
                _.mixin(css.input, {
                    marginTop: "-1px"
                });
            }
            return css;
        }();
        var EventBus = function() {
            "use strict";
            var namespace = "typeahead:";
            function EventBus(o) {
                if (!o || !o.el) {
                    $.error("EventBus initialized without el");
                }
                this.$el = $(o.el);
            }
            _.mixin(EventBus.prototype, {
                trigger: function(type) {
                    var args = [].slice.call(arguments, 1);
                    this.$el.trigger(namespace + type, args);
                }
            });
            return EventBus;
        }();
        var EventEmitter = function() {
            "use strict";
            var splitter = /\s+/, nextTick = getNextTick();
            return {
                onSync: onSync,
                onAsync: onAsync,
                off: off,
                trigger: trigger
            };
            function on(method, types, cb, context) {
                var type;
                if (!cb) {
                    return this;
                }
                types = types.split(splitter);
                cb = context ? bindContext(cb, context) : cb;
                this._callbacks = this._callbacks || {};
                while (type = types.shift()) {
                    this._callbacks[type] = this._callbacks[type] || {
                        sync: [],
                        async: []
                    };
                    this._callbacks[type][method].push(cb);
                }
                return this;
            }
            function onAsync(types, cb, context) {
                return on.call(this, "async", types, cb, context);
            }
            function onSync(types, cb, context) {
                return on.call(this, "sync", types, cb, context);
            }
            function off(types) {
                var type;
                if (!this._callbacks) {
                    return this;
                }
                types = types.split(splitter);
                while (type = types.shift()) {
                    delete this._callbacks[type];
                }
                return this;
            }
            function trigger(types) {
                var type, callbacks, args, syncFlush, asyncFlush;
                if (!this._callbacks) {
                    return this;
                }
                types = types.split(splitter);
                args = [].slice.call(arguments, 1);
                while ((type = types.shift()) && (callbacks = this._callbacks[type])) {
                    syncFlush = getFlush(callbacks.sync, this, [ type ].concat(args));
                    asyncFlush = getFlush(callbacks.async, this, [ type ].concat(args));
                    syncFlush() && nextTick(asyncFlush);
                }
                return this;
            }
            function getFlush(callbacks, context, args) {
                return flush;
                function flush() {
                    var cancelled;
                    for (var i = 0, len = callbacks.length; !cancelled && i < len; i += 1) {
                        cancelled = callbacks[i].apply(context, args) === false;
                    }
                    return !cancelled;
                }
            }
            function getNextTick() {
                var nextTickFn;
                if (window.setImmediate) {
                    nextTickFn = function nextTickSetImmediate(fn) {
                        setImmediate(function() {
                            fn();
                        });
                    };
                } else {
                    nextTickFn = function nextTickSetTimeout(fn) {
                        setTimeout(function() {
                            fn();
                        }, 0);
                    };
                }
                return nextTickFn;
            }
            function bindContext(fn, context) {
                return fn.bind ? fn.bind(context) : function() {
                    fn.apply(context, [].slice.call(arguments, 0));
                };
            }
        }();
        var highlight = function(doc) {
            "use strict";
            var defaults = {
                node: null,
                pattern: null,
                tagName: "strong",
                className: null,
                wordsOnly: false,
                caseSensitive: false
            };
            return function hightlight(o) {
                var regex;
                o = _.mixin({}, defaults, o);
                if (!o.node || !o.pattern) {
                    return;
                }
                o.pattern = _.isArray(o.pattern) ? o.pattern : [ o.pattern ];
                regex = getRegex(o.pattern, o.caseSensitive, o.wordsOnly);
                traverse(o.node, hightlightTextNode);
                function hightlightTextNode(textNode) {
                    var match, patternNode, wrapperNode;
                    if (match = regex.exec(textNode.data)) {
                        wrapperNode = doc.createElement(o.tagName);
                        o.className && (wrapperNode.className = o.className);
                        patternNode = textNode.splitText(match.index);
                        patternNode.splitText(match[0].length);
                        wrapperNode.appendChild(patternNode.cloneNode(true));
                        textNode.parentNode.replaceChild(wrapperNode, patternNode);
                    }
                    return !!match;
                }
                function traverse(el, hightlightTextNode) {
                    var childNode, TEXT_NODE_TYPE = 3;
                    for (var i = 0; i < el.childNodes.length; i++) {
                        childNode = el.childNodes[i];
                        if (childNode.nodeType === TEXT_NODE_TYPE) {
                            i += hightlightTextNode(childNode) ? 1 : 0;
                        } else {
                            traverse(childNode, hightlightTextNode);
                        }
                    }
                }
            };
            function getRegex(patterns, caseSensitive, wordsOnly) {
                var escapedPatterns = [], regexStr;
                for (var i = 0, len = patterns.length; i < len; i++) {
                    escapedPatterns.push(_.escapeRegExChars(patterns[i]));
                }
                regexStr = wordsOnly ? "\\b(" + escapedPatterns.join("|") + ")\\b" : "(" + escapedPatterns.join("|") + ")";
                return caseSensitive ? new RegExp(regexStr) : new RegExp(regexStr, "i");
            }
        }(window.document);
        var Input = function() {
            "use strict";
            var specialKeyCodeMap;
            specialKeyCodeMap = {
                9: "tab",
                27: "esc",
                37: "left",
                39: "right",
                13: "enter",
                38: "up",
                40: "down"
            };
            function Input(o) {
                var that = this, onBlur, onFocus, onKeydown, onInput;
                o = o || {};
                if (!o.input) {
                    $.error("input is missing");
                }
                onBlur = _.bind(this._onBlur, this);
                onFocus = _.bind(this._onFocus, this);
                onKeydown = _.bind(this._onKeydown, this);
                onInput = _.bind(this._onInput, this);
                this.$hint = $(o.hint);
                this.$input = $(o.input).on("blur.tt", onBlur).on("focus.tt", onFocus).on("keydown.tt", onKeydown);
                if (this.$hint.length === 0) {
                    this.setHint = this.getHint = this.clearHint = this.clearHintIfInvalid = _.noop;
                }
                if (!_.isMsie()) {
                    this.$input.on("input.tt", onInput);
                } else {
                    this.$input.on("keydown.tt keypress.tt cut.tt paste.tt", function($e) {
                        if (specialKeyCodeMap[$e.which || $e.keyCode]) {
                            return;
                        }
                        _.defer(_.bind(that._onInput, that, $e));
                    });
                }
                this.query = this.$input.val();
                this.$overflowHelper = buildOverflowHelper(this.$input);
            }
            Input.normalizeQuery = function(str) {
                return (str || "").replace(/^\s*/g, "").replace(/\s{2,}/g, " ");
            };
            _.mixin(Input.prototype, EventEmitter, {
                _onBlur: function onBlur() {
                    this.resetInputValue();
                    this.trigger("blurred");
                },
                _onFocus: function onFocus() {
                    this.trigger("focused");
                },
                _onKeydown: function onKeydown($e) {
                    var keyName = specialKeyCodeMap[$e.which || $e.keyCode];
                    this._managePreventDefault(keyName, $e);
                    if (keyName && this._shouldTrigger(keyName, $e)) {
                        this.trigger(keyName + "Keyed", $e);
                    }
                },
                _onInput: function onInput() {
                    this._checkInputValue();
                },
                _managePreventDefault: function managePreventDefault(keyName, $e) {
                    var preventDefault, hintValue, inputValue;
                    switch (keyName) {
                      case "tab":
                        hintValue = this.getHint();
                        inputValue = this.getInputValue();
                        preventDefault = hintValue && hintValue !== inputValue && !withModifier($e);
                        break;
      
                      case "up":
                      case "down":
                        preventDefault = !withModifier($e);
                        break;
      
                      default:
                        preventDefault = false;
                    }
                    preventDefault && $e.preventDefault();
                },
                _shouldTrigger: function shouldTrigger(keyName, $e) {
                    var trigger;
                    switch (keyName) {
                      case "tab":
                        trigger = !withModifier($e);
                        break;
      
                      default:
                        trigger = true;
                    }
                    return trigger;
                },
                _checkInputValue: function checkInputValue() {
                    var inputValue, areEquivalent, hasDifferentWhitespace;
                    inputValue = this.getInputValue();
                    areEquivalent = areQueriesEquivalent(inputValue, this.query);
                    hasDifferentWhitespace = areEquivalent ? this.query.length !== inputValue.length : false;
                    this.query = inputValue;
                    if (!areEquivalent) {
                        this.trigger("queryChanged", this.query);
                    } else if (hasDifferentWhitespace) {
                        this.trigger("whitespaceChanged", this.query);
                    }
                },
                focus: function focus() {
                    this.$input.focus();
                },
                blur: function blur() {
                    this.$input.blur();
                },
                getQuery: function getQuery() {
                    return this.query;
                },
                setQuery: function setQuery(query) {
                    this.query = query;
                },
                getInputValue: function getInputValue() {
                    return this.$input.val();
                },
                setInputValue: function setInputValue(value, silent) {
                    this.$input.val(value);
                    silent ? this.clearHint() : this._checkInputValue();
                },
                resetInputValue: function resetInputValue() {
                    this.setInputValue(this.query, true);
                },
                getHint: function getHint() {
                    return this.$hint.val();
                },
                setHint: function setHint(value) {
                    this.$hint.val(value);
                },
                clearHint: function clearHint() {
                    this.setHint("");
                },
                clearHintIfInvalid: function clearHintIfInvalid() {
                    var val, hint, valIsPrefixOfHint, isValid;
                    val = this.getInputValue();
                    hint = this.getHint();
                    valIsPrefixOfHint = val !== hint && hint.indexOf(val) === 0;
                    isValid = val !== "" && valIsPrefixOfHint && !this.hasOverflow();
                    !isValid && this.clearHint();
                },
                getLanguageDirection: function getLanguageDirection() {
                    return (this.$input.css("direction") || "ltr").toLowerCase();
                },
                hasOverflow: function hasOverflow() {
                    var constraint = this.$input.width() - 2;
                    this.$overflowHelper.text(this.getInputValue());
                    return this.$overflowHelper.width() >= constraint;
                },
                isCursorAtEnd: function() {
                    var valueLength, selectionStart, range;
                    valueLength = this.$input.val().length;
                    selectionStart = this.$input[0].selectionStart;
                    if (_.isNumber(selectionStart)) {
                        return selectionStart === valueLength;
                    } else if (document.selection) {
                        range = document.selection.createRange();
                        range.moveStart("character", -valueLength);
                        return valueLength === range.text.length;
                    }
                    return true;
                },
                destroy: function destroy() {
                    this.$hint.off(".tt");
                    this.$input.off(".tt");
                    this.$hint = this.$input = this.$overflowHelper = null;
                }
            });
            return Input;
            function buildOverflowHelper($input) {
                return $('<pre aria-hidden="true"></pre>').css({
                    position: "absolute",
                    visibility: "hidden",
                    whiteSpace: "pre",
                    fontFamily: $input.css("font-family"),
                    fontSize: $input.css("font-size"),
                    fontStyle: $input.css("font-style"),
                    fontVariant: $input.css("font-variant"),
                    fontWeight: $input.css("font-weight"),
                    wordSpacing: $input.css("word-spacing"),
                    letterSpacing: $input.css("letter-spacing"),
                    textIndent: $input.css("text-indent"),
                    textRendering: $input.css("text-rendering"),
                    textTransform: $input.css("text-transform")
                }).insertAfter($input);
            }
            function areQueriesEquivalent(a, b) {
                return Input.normalizeQuery(a) === Input.normalizeQuery(b);
            }
            function withModifier($e) {
                return $e.altKey || $e.ctrlKey || $e.metaKey || $e.shiftKey;
            }
        }();
        var Dataset = function() {
            "use strict";
            var datasetKey = "ttDataset", valueKey = "ttValue", datumKey = "ttDatum";
            function Dataset(o) {
                o = o || {};
                o.templates = o.templates || {};
                if (!o.source) {
                    $.error("missing source");
                }
                if (o.name && !isValidName(o.name)) {
                    $.error("invalid dataset name: " + o.name);
                }
                this.query = null;
                this.highlight = !!o.highlight;
                this.name = o.name || _.getUniqueId();
                this.source = o.source;
                this.displayFn = getDisplayFn(o.display || o.displayKey);
                this.templates = getTemplates(o.templates, this.displayFn);
                this.$el = $(html.dataset.replace("%CLASS%", this.name));
            }
            Dataset.extractDatasetName = function extractDatasetName(el) {
                return $(el).data(datasetKey);
            };
            Dataset.extractValue = function extractDatum(el) {
                return $(el).data(valueKey);
            };
            Dataset.extractDatum = function extractDatum(el) {
                return $(el).data(datumKey);
            };
            _.mixin(Dataset.prototype, EventEmitter, {
                _render: function render(query, suggestions) {
                    if (!this.$el) {
                        return;
                    }
                    var that = this, hasSuggestions;
                    this.$el.empty();
                    hasSuggestions = suggestions && suggestions.length;
                    if (!hasSuggestions && this.templates.empty) {
                        this.$el.html(getEmptyHtml()).prepend(that.templates.header ? getHeaderHtml() : null).append(that.templates.footer ? getFooterHtml() : null);
                    } else if (hasSuggestions) {
                        this.$el.html(getSuggestionsHtml()).prepend(that.templates.header ? getHeaderHtml() : null).append(that.templates.footer ? getFooterHtml() : null);
                    }
                    this.trigger("rendered");
                    function getEmptyHtml() {
                        return that.templates.empty({
                            query: query,
                            isEmpty: true
                        });
                    }
                    function getSuggestionsHtml() {
                        var $suggestions, nodes;
                        $suggestions = $(html.suggestions).css(css.suggestions);
                        nodes = _.map(suggestions, getSuggestionNode);
                        $suggestions.append.apply($suggestions, nodes);
                        that.highlight && highlight({
                            className: "tt-highlight",
                            node: $suggestions[0],
                            pattern: query
                        });
                        return $suggestions;
                        function getSuggestionNode(suggestion) {
                            var $el;
                            $el = $(html.suggestion).append(that.templates.suggestion(suggestion)).data(datasetKey, that.name).data(valueKey, that.displayFn(suggestion)).data(datumKey, suggestion);
                            $el.children().each(function() {
                                $(this).css(css.suggestionChild);
                            });
                            return $el;
                        }
                    }
                    function getHeaderHtml() {
                        return that.templates.header({
                            query: query,
                            isEmpty: !hasSuggestions
                        });
                    }
                    function getFooterHtml() {
                        return that.templates.footer({
                            query: query,
                            isEmpty: !hasSuggestions
                        });
                    }
                },
                getRoot: function getRoot() {
                    return this.$el;
                },
                update: function update(query) {
                    var that = this;
                    this.query = query;
                    this.canceled = false;
                    this.source(query, render);
                    function render(suggestions) {
                        if (!that.canceled && query === that.query) {
                            that._render(query, suggestions);
                        }
                    }
                },
                cancel: function cancel() {
                    this.canceled = true;
                },
                clear: function clear() {
                    this.cancel();
                    this.$el.empty();
                    this.trigger("rendered");
                },
                isEmpty: function isEmpty() {
                    return this.$el.is(":empty");
                },
                destroy: function destroy() {
                    this.$el = null;
                }
            });
            return Dataset;
            function getDisplayFn(display) {
                display = display || "value";
                return _.isFunction(display) ? display : displayFn;
                function displayFn(obj) {
                    return obj[display];
                }
            }
            function getTemplates(templates, displayFn) {
                return {
                    empty: templates.empty && _.templatify(templates.empty),
                    header: templates.header && _.templatify(templates.header),
                    footer: templates.footer && _.templatify(templates.footer),
                    suggestion: templates.suggestion || suggestionTemplate
                };
                function suggestionTemplate(context) {
                    return "<p>" + displayFn(context) + "</p>";
                }
            }
            function isValidName(str) {
                return /^[_a-zA-Z0-9-]+$/.test(str);
            }
        }();
        var Dropdown = function() {
            "use strict";
            function Dropdown(o) {
                var that = this, onSuggestionClick, onSuggestionMouseEnter, onSuggestionMouseLeave;
                o = o || {};
                if (!o.menu) {
                    $.error("menu is required");
                }
                this.isOpen = false;
                this.isEmpty = true;
                this.datasets = _.map(o.datasets, initializeDataset);
                onSuggestionClick = _.bind(this._onSuggestionClick, this);
                onSuggestionMouseEnter = _.bind(this._onSuggestionMouseEnter, this);
                onSuggestionMouseLeave = _.bind(this._onSuggestionMouseLeave, this);
                this.$menu = $(o.menu).on("click.tt", ".tt-suggestion", onSuggestionClick).on("mouseenter.tt", ".tt-suggestion", onSuggestionMouseEnter).on("mouseleave.tt", ".tt-suggestion", onSuggestionMouseLeave);
                _.each(this.datasets, function(dataset) {
                    that.$menu.append(dataset.getRoot());
                    dataset.onSync("rendered", that._onRendered, that);
                });
            }
            _.mixin(Dropdown.prototype, EventEmitter, {
                _onSuggestionClick: function onSuggestionClick($e) {
                    this.trigger("suggestionClicked", $($e.currentTarget));
                },
                _onSuggestionMouseEnter: function onSuggestionMouseEnter($e) {
                    this._removeCursor();
                    this._setCursor($($e.currentTarget), true);
                },
                _onSuggestionMouseLeave: function onSuggestionMouseLeave() {
                    this._removeCursor();
                },
                _onRendered: function onRendered() {
                    this.isEmpty = _.every(this.datasets, isDatasetEmpty);
                    this.isEmpty ? this._hide() : this.isOpen && this._show();
                    this.trigger("datasetRendered");
                    function isDatasetEmpty(dataset) {
                        return dataset.isEmpty();
                    }
                },
                _hide: function() {
                    this.$menu.hide();
                },
                _show: function() {
                    this.$menu.css("display", "block");
                },
                _getSuggestions: function getSuggestions() {
                    return this.$menu.find(".tt-suggestion");
                },
                _getCursor: function getCursor() {
                    return this.$menu.find(".tt-cursor").first();
                },
                _setCursor: function setCursor($el, silent) {
                    $el.first().addClass("tt-cursor");
                    !silent && this.trigger("cursorMoved");
                },
                _removeCursor: function removeCursor() {
                    this._getCursor().removeClass("tt-cursor");
                },
                _moveCursor: function moveCursor(increment) {
                    var $suggestions, $oldCursor, newCursorIndex, $newCursor;
                    if (!this.isOpen) {
                        return;
                    }
                    $oldCursor = this._getCursor();
                    $suggestions = this._getSuggestions();
                    this._removeCursor();
                    newCursorIndex = $suggestions.index($oldCursor) + increment;
                    newCursorIndex = (newCursorIndex + 1) % ($suggestions.length + 1) - 1;
                    if (newCursorIndex === -1) {
                        this.trigger("cursorRemoved");
                        return;
                    } else if (newCursorIndex < -1) {
                        newCursorIndex = $suggestions.length - 1;
                    }
                    this._setCursor($newCursor = $suggestions.eq(newCursorIndex));
                    this._ensureVisible($newCursor);
                },
                _ensureVisible: function ensureVisible($el) {
                    var elTop, elBottom, menuScrollTop, menuHeight;
                    elTop = $el.position().top;
                    elBottom = elTop + $el.outerHeight(true);
                    menuScrollTop = this.$menu.scrollTop();
                    menuHeight = this.$menu.height() + parseInt(this.$menu.css("paddingTop"), 10) + parseInt(this.$menu.css("paddingBottom"), 10);
                    if (elTop < 0) {
                        this.$menu.scrollTop(menuScrollTop + elTop);
                    } else if (menuHeight < elBottom) {
                        this.$menu.scrollTop(menuScrollTop + (elBottom - menuHeight));
                    }
                },
                close: function close() {
                    if (this.isOpen) {
                        this.isOpen = false;
                        this._removeCursor();
                        this._hide();
                        this.trigger("closed");
                    }
                },
                open: function open() {
                    if (!this.isOpen) {
                        this.isOpen = true;
                        !this.isEmpty && this._show();
                        this.trigger("opened");
                    }
                },
                setLanguageDirection: function setLanguageDirection(dir) {
                    this.$menu.css(dir === "ltr" ? css.ltr : css.rtl);
                },
                moveCursorUp: function moveCursorUp() {
                    this._moveCursor(-1);
                },
                moveCursorDown: function moveCursorDown() {
                    this._moveCursor(+1);
                },
                getDatumForSuggestion: function getDatumForSuggestion($el) {
                    var datum = null;
                    if ($el.length) {
                        datum = {
                            raw: Dataset.extractDatum($el),
                            value: Dataset.extractValue($el),
                            datasetName: Dataset.extractDatasetName($el)
                        };
                    }
                    return datum;
                },
                getDatumForCursor: function getDatumForCursor() {
                    return this.getDatumForSuggestion(this._getCursor().first());
                },
                getDatumForTopSuggestion: function getDatumForTopSuggestion() {
                    return this.getDatumForSuggestion(this._getSuggestions().first());
                },
                update: function update(query) {
                    _.each(this.datasets, updateDataset);
                    function updateDataset(dataset) {
                        dataset.update(query);
                    }
                },
                empty: function empty() {
                    _.each(this.datasets, clearDataset);
                    this.isEmpty = true;
                    function clearDataset(dataset) {
                        dataset.clear();
                    }
                },
                isVisible: function isVisible() {
                    return this.isOpen && !this.isEmpty;
                },
                destroy: function destroy() {
                    this.$menu.off(".tt");
                    this.$menu = null;
                    _.each(this.datasets, destroyDataset);
                    function destroyDataset(dataset) {
                        dataset.destroy();
                    }
                }
            });
            return Dropdown;
            function initializeDataset(oDataset) {
                return new Dataset(oDataset);
            }
        }();
        var Typeahead = function() {
            "use strict";
            var attrsKey = "ttAttrs";
            function Typeahead(o) {
                var $menu, $input, $hint;
                o = o || {};
                if (!o.input) {
                    $.error("missing input");
                }
                this.isActivated = false;
                this.autoselect = !!o.autoselect;
                this.minLength = _.isNumber(o.minLength) ? o.minLength : 1;
                this.$node = buildDom(o.input, o.withHint);
                $menu = this.$node.find(".tt-dropdown-menu");
                $input = this.$node.find(".tt-input");
                $hint = this.$node.find(".tt-hint");
                $input.on("blur.tt", function($e) {
                    var active, isActive, hasActive;
                    active = document.activeElement;
                    isActive = $menu.is(active);
                    hasActive = $menu.has(active).length > 0;
                    if (_.isMsie() && (isActive || hasActive)) {
                        $e.preventDefault();
                        $e.stopImmediatePropagation();
                        _.defer(function() {
                            $input.focus();
                        });
                    }
                });
                $menu.on("mousedown.tt", function($e) {
                    $e.preventDefault();
                });
                this.eventBus = o.eventBus || new EventBus({
                    el: $input
                });
                this.dropdown = new Dropdown({
                    menu: $menu,
                    datasets: o.datasets
                }).onSync("suggestionClicked", this._onSuggestionClicked, this).onSync("cursorMoved", this._onCursorMoved, this).onSync("cursorRemoved", this._onCursorRemoved, this).onSync("opened", this._onOpened, this).onSync("closed", this._onClosed, this).onAsync("datasetRendered", this._onDatasetRendered, this);
                this.input = new Input({
                    input: $input,
                    hint: $hint
                }).onSync("focused", this._onFocused, this).onSync("blurred", this._onBlurred, this).onSync("enterKeyed", this._onEnterKeyed, this).onSync("tabKeyed", this._onTabKeyed, this).onSync("escKeyed", this._onEscKeyed, this).onSync("upKeyed", this._onUpKeyed, this).onSync("downKeyed", this._onDownKeyed, this).onSync("leftKeyed", this._onLeftKeyed, this).onSync("rightKeyed", this._onRightKeyed, this).onSync("queryChanged", this._onQueryChanged, this).onSync("whitespaceChanged", this._onWhitespaceChanged, this);
                this._setLanguageDirection();
            }
            _.mixin(Typeahead.prototype, {
                _onSuggestionClicked: function onSuggestionClicked(type, $el) {
                    var datum;
                    if (datum = this.dropdown.getDatumForSuggestion($el)) {
                        this._select(datum);
                    }
                },
                _onCursorMoved: function onCursorMoved() {
                    var datum = this.dropdown.getDatumForCursor();
                    this.input.setInputValue(datum.value, true);
                    this.eventBus.trigger("cursorchanged", datum.raw, datum.datasetName);
                },
                _onCursorRemoved: function onCursorRemoved() {
                    this.input.resetInputValue();
                    this._updateHint();
                },
                _onDatasetRendered: function onDatasetRendered() {
                    this._updateHint();
                },
                _onOpened: function onOpened() {
                    this._updateHint();
                    this.eventBus.trigger("opened");
                },
                _onClosed: function onClosed() {
                    this.input.clearHint();
                    this.eventBus.trigger("closed");
                },
                _onFocused: function onFocused() {
                    this.isActivated = true;
                    this.dropdown.open();
                },
                _onBlurred: function onBlurred() {
                    this.isActivated = false;
                    this.dropdown.empty();
                    this.dropdown.close();
                },
                _onEnterKeyed: function onEnterKeyed(type, $e) {
                    var cursorDatum, topSuggestionDatum;
                    cursorDatum = this.dropdown.getDatumForCursor();
                    topSuggestionDatum = this.dropdown.getDatumForTopSuggestion();
                    if (cursorDatum) {
                        this._select(cursorDatum);
                        $e.preventDefault();
                    } else if (this.autoselect && topSuggestionDatum) {
                        this._select(topSuggestionDatum);
                        $e.preventDefault();
                    }
                },
                _onTabKeyed: function onTabKeyed(type, $e) {
                    var datum;
                    if (datum = this.dropdown.getDatumForCursor()) {
                        this._select(datum);
                        $e.preventDefault();
                    } else {
                        this._autocomplete(true);
                    }
                },
                _onEscKeyed: function onEscKeyed() {
                    this.dropdown.close();
                    this.input.resetInputValue();
                },
                _onUpKeyed: function onUpKeyed() {
                    var query = this.input.getQuery();
                    this.dropdown.isEmpty && query.length >= this.minLength ? this.dropdown.update(query) : this.dropdown.moveCursorUp();
                    this.dropdown.open();
                },
                _onDownKeyed: function onDownKeyed() {
                    var query = this.input.getQuery();
                    this.dropdown.isEmpty && query.length >= this.minLength ? this.dropdown.update(query) : this.dropdown.moveCursorDown();
                    this.dropdown.open();
                },
                _onLeftKeyed: function onLeftKeyed() {
                    this.dir === "rtl" && this._autocomplete();
                },
                _onRightKeyed: function onRightKeyed() {
                    this.dir === "ltr" && this._autocomplete();
                },
                _onQueryChanged: function onQueryChanged(e, query) {
                    this.input.clearHintIfInvalid();
                    query.length >= this.minLength ? this.dropdown.update(query) : this.dropdown.empty();
                    this.dropdown.open();
                    this._setLanguageDirection();
                },
                _onWhitespaceChanged: function onWhitespaceChanged() {
                    this._updateHint();
                    this.dropdown.open();
                },
                _setLanguageDirection: function setLanguageDirection() {
                    var dir;
                    if (this.dir !== (dir = this.input.getLanguageDirection())) {
                        this.dir = dir;
                        this.$node.css("direction", dir);
                        this.dropdown.setLanguageDirection(dir);
                    }
                },
                _updateHint: function updateHint() {
                    var datum, val, query, escapedQuery, frontMatchRegEx, match;
                    datum = this.dropdown.getDatumForTopSuggestion();
                    if (datum && this.dropdown.isVisible() && !this.input.hasOverflow()) {
                        val = this.input.getInputValue();
                        query = Input.normalizeQuery(val);
                        escapedQuery = _.escapeRegExChars(query);
                        frontMatchRegEx = new RegExp("^(?:" + escapedQuery + ")(.+$)", "i");
                        match = frontMatchRegEx.exec(datum.value);
                        match ? this.input.setHint(val + match[1]) : this.input.clearHint();
                    } else {
                        this.input.clearHint();
                    }
                },
                _autocomplete: function autocomplete(laxCursor) {
                    var hint, query, isCursorAtEnd, datum;
                    hint = this.input.getHint();
                    query = this.input.getQuery();
                    isCursorAtEnd = laxCursor || this.input.isCursorAtEnd();
                    if (hint && query !== hint && isCursorAtEnd) {
                        datum = this.dropdown.getDatumForTopSuggestion();
                        datum && this.input.setInputValue(datum.value);
                        this.eventBus.trigger("autocompleted", datum.raw, datum.datasetName);
                    }
                },
                _select: function select(datum) {
                    this.input.setQuery(datum.value);
                    this.input.setInputValue(datum.value, true);
                    this._setLanguageDirection();
                    this.eventBus.trigger("selected", datum.raw, datum.datasetName);
                    this.dropdown.close();
                    _.defer(_.bind(this.dropdown.empty, this.dropdown));
                },
                open: function open() {
                    this.dropdown.open();
                },
                close: function close() {
                    this.dropdown.close();
                },
                setVal: function setVal(val) {
                    val = _.toStr(val);
                    if (this.isActivated) {
                        this.input.setInputValue(val);
                    } else {
                        this.input.setQuery(val);
                        this.input.setInputValue(val, true);
                    }
                    this._setLanguageDirection();
                },
                getVal: function getVal() {
                    return this.input.getQuery();
                },
                destroy: function destroy() {
                    this.input.destroy();
                    this.dropdown.destroy();
                    destroyDomStructure(this.$node);
                    this.$node = null;
                }
            });
            return Typeahead;
            function buildDom(input, withHint) {
                var $input, $wrapper, $dropdown, $hint;
                $input = $(input);
                $wrapper = $(html.wrapper).css(css.wrapper);
                $dropdown = $(html.dropdown).css(css.dropdown);
                $hint = $input.clone().css(css.hint).css(getBackgroundStyles($input));
                $hint.val("").removeData().addClass("tt-hint").removeAttr("id name placeholder required").prop("readonly", true).attr({
                    autocomplete: "off",
                    spellcheck: "false",
                    tabindex: -1
                });
                $input.data(attrsKey, {
                    dir: $input.attr("dir"),
                    autocomplete: $input.attr("autocomplete"),
                    spellcheck: $input.attr("spellcheck"),
                    style: $input.attr("style")
                });
                $input.addClass("tt-input").attr({
                    autocomplete: "off",
                    spellcheck: false
                }).css(withHint ? css.input : css.inputWithNoHint);
                try {
                    !$input.attr("dir") && $input.attr("dir", "auto");
                } catch (e) {}
                return $input.wrap($wrapper).parent().prepend(withHint ? $hint : null).append($dropdown);
            }
            function getBackgroundStyles($el) {
                return {
                    backgroundAttachment: $el.css("background-attachment"),
                    backgroundClip: $el.css("background-clip"),
                    backgroundColor: $el.css("background-color"),
                    backgroundImage: $el.css("background-image"),
                    backgroundOrigin: $el.css("background-origin"),
                    backgroundPosition: $el.css("background-position"),
                    backgroundRepeat: $el.css("background-repeat"),
                    backgroundSize: $el.css("background-size")
                };
            }
            function destroyDomStructure($node) {
                var $input = $node.find(".tt-input");
                _.each($input.data(attrsKey), function(val, key) {
                    _.isUndefined(val) ? $input.removeAttr(key) : $input.attr(key, val);
                });
                $input.detach().removeData(attrsKey).removeClass("tt-input").insertAfter($node);
                $node.remove();
            }
        }();
        (function() {
            "use strict";
            var old, typeaheadKey, methods;
            old = $.fn.typeahead;
            typeaheadKey = "ttTypeahead";
            methods = {
                initialize: function initialize(o, datasets) {
                    datasets = _.isArray(datasets) ? datasets : [].slice.call(arguments, 1);
                    o = o || {};
                    return this.each(attach);
                    function attach() {
                        var $input = $(this), eventBus, typeahead;
                        _.each(datasets, function(d) {
                            d.highlight = !!o.highlight;
                        });
                        typeahead = new Typeahead({
                            input: $input,
                            eventBus: eventBus = new EventBus({
                                el: $input
                            }),
                            withHint: _.isUndefined(o.hint) ? true : !!o.hint,
                            minLength: o.minLength,
                            autoselect: o.autoselect,
                            datasets: datasets
                        });
                        $input.data(typeaheadKey, typeahead);
                    }
                },
                open: function open() {
                    return this.each(openTypeahead);
                    function openTypeahead() {
                        var $input = $(this), typeahead;
                        if (typeahead = $input.data(typeaheadKey)) {
                            typeahead.open();
                        }
                    }
                },
                close: function close() {
                    return this.each(closeTypeahead);
                    function closeTypeahead() {
                        var $input = $(this), typeahead;
                        if (typeahead = $input.data(typeaheadKey)) {
                            typeahead.close();
                        }
                    }
                },
                val: function val(newVal) {
                    return !arguments.length ? getVal(this.first()) : this.each(setVal);
                    function setVal() {
                        var $input = $(this), typeahead;
                        if (typeahead = $input.data(typeaheadKey)) {
                            typeahead.setVal(newVal);
                        }
                    }
                    function getVal($input) {
                        var typeahead, query;
                        if (typeahead = $input.data(typeaheadKey)) {
                            query = typeahead.getVal();
                        }
                        return query;
                    }
                },
                destroy: function destroy() {
                    return this.each(unattach);
                    function unattach() {
                        var $input = $(this), typeahead;
                        if (typeahead = $input.data(typeaheadKey)) {
                            typeahead.destroy();
                            $input.removeData(typeaheadKey);
                        }
                    }
                }
            };
            $.fn.typeahead = function(method) {
                var tts;
                if (methods[method] && method !== "initialize") {
                    tts = this.filter(function() {
                        return !!$(this).data(typeaheadKey);
                    });
                    return methods[method].apply(tts, [].slice.call(arguments, 1));
                } else {
                    return methods.initialize.apply(this, arguments);
                }
            };
            $.fn.typeahead.noConflict = function noConflict() {
                $.fn.typeahead = old;
                return this;
            };
        })();
      })(window.jQuery);
}