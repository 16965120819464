// Used in: event_panel_things.js.erb
function init_backbone_templates(flagged_event) {
  window.EventModal = Backbone.View.extend({
    tagName: "div",
    className: "event_wrapper event_modal",
    events: {
      "click .event_actions": "event_actions",
      "click .flag_event_wrapper": "flag_event_wrapper",
      "click .close_x": "close_event_modal",
    },
    initialize: function () {
      this.render();
    },
    render: function () {
      this.$el.html(JST["templates/shared/event_modal"](this.model));
      return this;
    },
    event_actions: function (e) {
      e.stopPropagation();
    },
    kill_event_panel: function (e) {
      Zymewire.event_panel.event_id = null
      Zymewire.event_panel.org_id = null
      Zymewire.event_panel.data = null
      Zymewire.event_panel.icon_id = null
    },
    close_event_modal: function (e) {
      this.kill_event_panel();
      close_modal_control();
      return false;
    },
    flag_event_wrapper: function (e) {
      this.kill_event_panel();
      flag_event($(this).attr('data-id'));
      return false;
    }
  });

  window.OrganizationSection = Backbone.View.extend({
    tagName: "div",
    className: "event_organizations",
    events: {
      "click .ep_list_menu": "event_panel_icon_click",
      "click .event_panel_icon_div": "event_panel_icon_click"
    },
    initialize: function () {
      this.render();
    },
    render: function () {
      this.model.authorized_sales_force = Zymewire.user.authorized_sales_force;
      this.$el.html(JST["templates/shared/organizations_section"](this.model));
      return this;
    },
    event_panel_icon_click: function (e) {
      var icon_id = $(e.currentTarget).attr('id')
      var event_id = $(e.currentTarget).parents('.event_organization_line').attr('event_id')
      var org_id = $(e.currentTarget).parents('.event_organization_line').attr('org_id')
      var context = $(e.currentTarget).parents('.event_organization_line').attr('context')
      var company_name = $(e.currentTarget).parents('.event_organization_line').attr('company_name')
      var data = $(e.currentTarget).attr('data')
      if (Zymewire.event_panel.event_id == event_id && Zymewire.event_panel.org_id == org_id && Zymewire.event_panel.data == data) {
        $('#' + context + '_' + Zymewire.event_panel.event_id + '_' + Zymewire.event_panel.org_id).removeClass('holder_open')
        $('#' + context + '_' + Zymewire.event_panel.org_id + '_' + Zymewire.event_panel.event_id).removeClass('holder_open')
        $('#' + context + '_' + Zymewire.event_panel.org_id + '_' + Zymewire.event_panel.event_id).html('')
        Zymewire.event_panel.event_id = null
        Zymewire.event_panel.org_id = null
        Zymewire.event_panel.data = null
        Zymewire.event_panel.icon_id = null
        manage_icons(icon_id, 'show_icon')
      }
      else {
        $(e.currentTarget).parents('.event_organization_line').addClass('holder_open')
        $(e.currentTarget).parents('.event_organization_line').next().addClass('holder_open')
        if (Zymewire.event_panel.org_id != null && (Zymewire.event_panel.event_id != event_id || Zymewire.event_panel.org_id != org_id)) {
          $('#' + context + '_' + Zymewire.event_panel.event_id + '_' + Zymewire.event_panel.org_id).removeClass('holder_open')
          $('#' + context + '_' + Zymewire.event_panel.org_id + '_' + Zymewire.event_panel.event_id).removeClass('holder_open')
          $('#' + context + '_' + Zymewire.event_panel.org_id + '_' + Zymewire.event_panel.event_id).html('')
          manage_icons(Zymewire.event_panel.icon_id, 'show_icon')
        }
        else if (Zymewire.event_panel.data != null && Zymewire.event_panel.data != data) {
          manage_icons(Zymewire.event_panel.icon_id, 'show_icon')
        }
        if (data != 'lists') {
          manage_icons(icon_id, 'show_closed')
          Zymewire.event_panel.icon_id = icon_id
        }
        Zymewire.event_panel.event_id = event_id
        Zymewire.event_panel.org_id = org_id
        Zymewire.event_panel.data = data
        if (data == 'lists') {
          get_lists_info(event_id, org_id, context, company_name)
          Zymewire.mixpanel_track("New event panel icon click", {icon: 'lists'});
        }
        else if (data == 'sf') {
          get_sf_info(event_id, org_id, context, company_name)
          Zymewire.mixpanel_track("New event panel icon click", {icon: 'sf'});
        }
        else {
          $(e.currentTarget).parents(".event_organization_line").next().html('<span class="event_organization_data_content">' + data + '</span>')
          var height = $(e.currentTarget).parents(".event_organization_line").next().find('.event_organization_data_content').height()
          height = (height > 200) ? 200 : height
          $(e.currentTarget).parents(".event_organization_line").next().stop().animate({opacity:1, height:height+40}, 200)
          Zymewire.mixpanel_track("New event panel icon click", {icon: 'sponsor'});
        }
      }
    }
  });

  window.EventView = Backbone.View.extend({

    events: {
      "click .event_actions": "event_actions",
      "click .event_hover": "event_hover",
      "click .sdh_passage": "sdh_passage",
      "click .flag_event_wrapper": "flag_event_wrapper",
    },

    render: function () {
      if(flagged_event){
        this.model.flagged = true;
      }
      this.model.date_marker = false;
      this.model.authorized_sales_force = Zymewire.user.authorized_sales_force;
      if (this.model.attributes) {
        $(this.el).html(JST["templates/shared/event_panel"](this.model.attributes));
      }
      else {
        $(this.el).html(JST["templates/shared/event_panel"](this.model));
      }
      this.model.context = 'event'
      var rendered_organizations = new OrganizationSection({model: this.model});
      $(this.el).find('.event_actions').prepend(rendered_organizations.el)
      if (Zymewire.trigger_source) {
        var current_target = {currentTarget: this.el}
      }
      return this;
    },
    flag_event_wrapper: function (e) {
      e.stopPropagation();
      flag_event($(e.currentTarget).attr('data-id'));
      return false;
    },
    event_actions: function (e) {
      e.stopPropagation();
    },
    event_hover: function (e) {
      if(this.model.category == 'opinion') {
        this.opinion();
        return;
      }

      if(this.model.source_document_hidden) {
        this.sourceDocHidden();
        return;
      }

      source_modal(this.model.id, this.model);
    },
    sdh_passage: function (e) {
      if(this.model.category == 'opinion') {
        this.opinion();
        return;
      }

      if(this.model.source_document_hidden) {
        this.sourceDocHidden();
        return;
      }

      source_modal(this.model.id, this.model, e.target.dataset.count);
    },

    opinion: function () {
      open_opinion(this.model.id, this.model.source_url);
    },

    sourceDocHidden: function() {
      track_engagement('Source Document hidden source opened', {event_id: this.model.id});
      window.open(this.model.source_url, '_blank');
    }
  });
}

window.init_backbone_templates = init_backbone_templates
